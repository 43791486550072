export const ddtMixin = {
  methods: {
    processDdtForMail (ddt) {
      ddt.voci = []
      let lastOrd = 0
      ddt.ordini.forEach(function (o) {
        let voce = { codice: o.codice_finito, qta: o.qta_bancali, um: 'NR' }
        voce.descr = ''

        if (o.opera || o.prodotto || o.numero) {
          voce.descr = '<div>'
          if (o.opera) voce.descr += `${o.opera}`
          if (o.prodotto) voce.descr += ` ${o.prodotto}`
          if (o.numero) voce.descr += ` ${o.numero}`

          if (o.tipo_imballo === 1) {
            let numScatole = parseInt(o.qta_bancali / o.copie)
            let resto = o.qta_bancali % o.copie

            voce.descr += ' ('
            if (numScatole) {
              voce.descr += `${numScatole} scatole da ${o.copie} pezzi`
            }
            if (resto) {
              if (numScatole) voce.descr += ' + '
              voce.descr += `1 scatola da ${resto} pezzi`
            }
            voce.descr += ')'
          }
          voce.descr += '</div>'
        }
        if (o.descrizione) voce.descr += `<div>${o.descrizione}</div>`
        if (o.tipo_lavorazione) voce.descr += `<div>${o.tipo_lavorazione}</div>`
        if (o.note) voce.descr += `<div>${o.note}</div>`
        ddt.voci.push(voce)
        if (lastOrd !== o.ordine_id && o.note_ordine) {
          ddt.note += `<div>${o.note_ordine}</div>`
          lastOrd = o.ordine_id
        }
      })

      if (ddt.merce && ddt.merce.length) {
        ddt.merce.forEach(function (m) {
          let voce = { codice: m.codice, qta: m.qta, um: m.unita_misura, descr: m.descrizione }
          ddt.voci.push(voce)
        })
      }

      return ddt
    },
    getInscatolatura (bancale) {
      let numScatole = parseInt(bancale.qta / bancale.copie)
      let resto = bancale.qta % bancale.copie
      let descr = ''

      if (numScatole) {
        descr += `${numScatole} scatole da ${bancale.copie} pezzi`
      }
      if (resto) {
        if (numScatole) descr += ' + '
        descr += `1 scatola da ${resto} pezzi`
      }
      return descr
    }
  }
}
