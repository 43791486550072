<template>
  <v-form ref="formDdt" v-model="validFormDdt">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex xs12 lg6>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                label="Numero bolla"
                v-if="ddt._id"
                :value="ddt.progressivo+'-'+ddt.esercizio"
                disabled />
              <v-text-field
                label="Causale"
                v-model="ddt.causale"
                :rules="[$rules.maxlength(50)]" />
              <date-picker
                v-model="ddt.data"
                label="Data"
                name="dataddt" />
              <date-picker
                v-model="ddt.inizio_trasporto"
                label="Data inizio trasporto"
                name="inizio_trasporto" />
              <v-select
                label="Trasporto a cura di"
                :items="corrieri"
                item-text="nome"
                item-value="_id"
                clearable
                v-model="ddt.corriere_id" />
              <v-textarea
                auto-grow
                label="Note"
                v-model="ddt.note"
                :rules="[$rules.maxlength(4000)]" />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 lg6>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select
                label="Cliente"
                :items="clienti"
                item-text="ragione_sociale"
                item-value="cliente_id"
                v-model="ddt.cliente_id"
                :disabled="destNonAnag || (!!ddt.has_bancali && ddt.has_bancali > 0)"
                @input="changedCliente" />
              <v-select
                label="Destinazione"
                :items="destinazioni"
                item-text="presso"
                item-value="destinazione_id"
                v-model="ddt.destinazione_id"
                :disabled="!ddt.cliente_id || (!!ddt.has_bancali && ddt.has_bancali > 0)" />
              <v-checkbox
                v-if="!ddt.has_bancali"
                v-model="destNonAnag"
                label="Cliente non in anagrafica"
                @change="changeNonAnag"
                fixed right bottom />
              <v-textarea
                v-if="!ddt.has_bancali && destNonAnag"
                auto-grow
                label="Destinazione merce"
                v-model="ddt.destinazione"
                :rules="[$rules.maxlength(4000)]" />
              <v-select
                label="Aspetto esteriore beni*"
                v-model="ddt.aspetto"
                :items="aspetti"
                item-text="descr"
                item-value="id" />
              <v-text-field
                label="Lista di carico"
                v-model="ddt.lista_carico"
                :rules="[$rules.maxlength(100)]" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex>
          <v-btn
            class="ml-0"
            color="primary"
            :disabled="!validFormDdt || (!this.ddt.destinazione_id && !this.ddt.destinazione) || this.ddt.aspetto == null"
            @click.native="save()">
            Salva
          </v-btn>
          <v-btn
            v-if="ddt._id"
            @click="stampa()">
            Stampa
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { $EventBus } from '@/eventBus'
import datePicker from '@/components/common/DatePicker'
import { ddtMixin } from '@/components/produzione/ddt/ddtMixin'

export default {
  mixins: [ddtMixin],
  props: {
    ddt: Object
  },
  components: {
    datePicker
  },
  data: () => ({
    validFormDdt: false,
    clienti: [],
    corrieri: [],
    destinazioni: [],
    destNonAnag: false,
    aspetti: [{ descr: 'Bancali', id: 0 }, { descr: 'Scatole', id: 1 }, { descr: 'A vista', id: 2 }]
  }),
  methods: {
    getClienti (ddtId, cliId) {
      let t = this
      t.$plsqlMethod('ddtp', 'get_clienti', { ddt_id: ddtId, cliente_id: cliId })
        .then(response => {
          t.clienti = response.data
          if (t.ddt._id) {
            if (t.ddt.destinazione_id) {
              let cliente = t.clienti.find(function (c) { return c.cliente_id === t.ddt.cliente_id })
              t.destinazioni = cliente.destinazioni
            } else {
              t.destNonAnag = true
            }
          }
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Clienti' })
        })
    },
    getCorrieri () {
      let t = this
      t.$plsqlReadAll('corriere', { rows4page: 10000 })
        .then(response => {
          t.corrieri = response.data.items
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Corrieri' })
        })
    },
    changeNonAnag: function () {
      if (!this.ddt.has_bancali) {
        if (this.destNonAnag) {
          this.ddt.cliente_id = null
          this.ddt.destinazione_id = null
        } else {
          this.ddt.destinazione = null
        }
      }
    },
    changedCliente () {
      let idc = this.ddt.cliente_id
      let cliente = this.clienti.find(function (c) { return c.cliente_id === idc })
      this.destinazioni = cliente.destinazioni
      if (this.destinazioni.length === 1) {
        this.ddt.destinazione_id = this.destinazioni[0].destinazione_id
      } else {
        delete this.ddt.destinazione_id
      }
    },
    save () {
      if (this.$refs.formDdt.validate()) {
        if (!this.ddt.destinazione_id && !this.ddt.destinazione) {
          $EventBus.$emit('message', { type: 'error', text: 'La destinazione è obbligatoria' })
        } else {
          this.$plsqlSave('ddtp', this.ddt)
            .then(response => {
              $EventBus.$emit('message', {
                type: 'success',
                text: 'DDT salvato'
              })
              if (this.$route.params.id === 'add') {
                $EventBus.$emit('ddt:added', response.data)
              }
            })
            .catch(err => {
              console.log(err)
              $EventBus.$emit('message', { type: 'error', text: 'Errore salvataggio DDT' })
            })
        }
      }
    },
    stampa () {
      let processDdtForMail = this.processDdtForMail
      this.$plsqlMethod('ddtp', 'get_ddt_for_mail', { ddts: [this.ddt._id] })
        .then((response) => {
          response.data.forEach(function (ddt) {
            ddt = processDdtForMail(ddt)
          })

          this.$http({
            method: 'post',
            url: '/mail/ddt/pdf',
            responseType: 'blob',
            data: { ddts: response.data }
          }).then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            this.$openFileNewTab(blob) // con adblocker picche ocio
          }).catch((err) => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare il pdf del ddt' })
          })
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare il pdf del ddt' })
        })
    }
  },
  mounted () {
    setTimeout(() => {
      this.getCorrieri()
    }, 500)
  }
}
</script>
